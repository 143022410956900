import React, { useState, useRef } from 'react';
import './ContentBox.css';
import audioFile from '../music/sound.mp3'; // Reemplaza con la ruta correcta de tu archivo de audio


const ContentBox = ({ images, texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const audioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);

  const handleClick = () => {
    const lastIndex = images.length - 1;

    if (currentIndex < lastIndex) {
      setCurrentIndex(currentIndex + 1);
    } else {
      // Si es el último elemento, redirige a la URL deseada
      window.open('https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2EfZSuH24xPvr1p2jom3sMQG8At9PdqRvc1PJtiqzMoN&fixed=in');
      setCurrentIndex(0);
    }

    // Reproducir el audio al hacer clic
    if (audioRef.current) {
      audioRef.current.play();
    }
  };


  const handleMuteToggle = () => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

    return (
      <div className="content-box" onClick={handleClick}>
        <div className="images">
          {images &&
            images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                style={{ display: index === currentIndex ? 'block' : 'none' }}
              />
            ))}
        </div>
        <div className="pokemon-textbox">
          <p>{texts && texts[currentIndex]}</p>
        </div>
        <audio ref={audioRef} src={audioFile} />
        <button className="mute-button" onClick={(e) => { e.stopPropagation(); handleMuteToggle(); }}>
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
      </div>
    );
  };

export default ContentBox;
