import React from 'react';
import './Section1.css';
import oak from "../images/oak.png"
import features from "../images/features.png"
import gay from "../images/gay.png"
import based from "../images/based.png"
import callers from "../images/callers.gif"
import diamond from "../images/diamond.png"
import holders from "../images/holder.png"

const Section1 = () => {

    return (
        <div className="section-content">
            <img src={features} alt="Imagen Features" className="features-image" />
            <div className='content'>
                <div className="text-from-left text-from-left-shadow ">
                    <img src={gay} alt="gay" className='content-img' />
                    <p>We don't identify as men or women, but we're gay</p>
                </div>
                <div className="image-from-right">
                    <img src={oak} alt="Imagen desde la derecha" className="image" />
                </div>
            </div>
            <div className='content'>
                <div className="text-from-left">
                    <img src={callers} alt="Imagen desde la derecha" className="image" />
                </div>
                <div className="image-from-right text-from-left-shadow">
                    <img src={based} alt="gay" className='content-img' />
                    <p>We'll pay some callers</p>
                </div>
            </div>
            <div className='content'>
                <div className="text-from-left text-from-left-shadow ">
                    <img src={diamond} alt="gay" className='content-img' />
                    <p>🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍🦍.sol will be scalping this token all day!</p>
                </div>
                <div className="image-from-right">
                    <img src={holders} alt="Imagen desde la derecha" className="image" />
                </div>
            </div>
        </div>
    );
};

export default Section1;
