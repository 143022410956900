import React from 'react';
import './Footer.css';
import logo from '../images/title.png'; // Ajusta la ruta según la ubicación de tu logo

const Footer = () => {
  return (
    <footer className="footer-container">
      <img src={logo} alt="Logo" className="footer-logo" />
    </footer>
  );
};

export default Footer;
