import React from 'react';
import './App.css';
import Gameboy from './components/Gameboy.js';
import ContentBox from './components/ContentBox.js';
import TitleComponent from './components/TitleComponent';
import Carousel from './components/Carousel.js';
import Section1 from './components/Section1.js';
import Footer from './components/Footer.js'
import Roadmap from './components/Roadmap.js'
import data from './data';
import imageData from './utils/imageData.js';

const App = () => {
  return (
    <div className="app-container">
      <div className="header-section">
        <TitleComponent />
      </div>
      <div className="gameboy-section">
        <Gameboy>
          <ContentBox images={data.images} texts={data.text} />
        </Gameboy>
      </div>
      <div className="carousel-section">
        <Carousel images={imageData} />
      </div>
      <div className="additional-section">
        <Section1/>
      </div>
      <div className="additional-section">
        <Roadmap/>
      </div>
      <Footer /> 
    </div>
  );
};

export default App;
