import React from 'react';
import './Roadmap.css';
import title from "../images/roadmaptitle.png"
import roadmap from "../images/roadmap.png"

const Roadmap = () => {
    return (
        <>
            <div className="roadmap-section title">
                <img src={title} alt='roadmpa-title' />
            </div>
            <div className="roadmap-section roadmap">
                <img src={roadmap} alt='roadmap-img' />
            </div>
        </>
    );
};

export default Roadmap;
