import React from 'react';
import b1 from "../images/b1.png"
import b2 from "../images/b2.png"
import b3 from "../images/b3.png"
import b4 from "../images/b4.png"
import title from "../images/title.png"
import "./TitleComponent.css"

const TitleComponent = () => {
    return (
        <div className="title-component">
            <img src={title} alt="Title" className="title-image" />
            <div className="button-row">
                <a href="https://t.me/PokeSolToken" target='_blank' rel="noopener noreferrer">
                    <img src={b1} alt="telegram" className="button-image" />
                </a>
                <a href="https://twitter.com/PokesolToken" target='_blank' rel="noopener noreferrer">
                    <img src={b2} alt="twitter" className="button-image" />
                </a>
                <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2EfZSuH24xPvr1p2jom3sMQG8At9PdqRvc1PJtiqzMoN&fixed=in" target='_blank' rel="noopener noreferrer">
                    <img src={b3} alt="buy" className="button-image" />
                </a>
                <a href="https://birdeye.so/token/2EfZSuH24xPvr1p2jom3sMQG8At9PdqRvc1PJtiqzMoN?chain=solana" target='_blank' rel="noopener noreferrer">
                    <img src={b4} alt="chart" className="button-image" />
                </a>
            </div>
        </div>
    );
};

export default TitleComponent;
