import image1 from "../images/carousel/c1.gif"
import image2 from "../images/carousel/c2.png"
import image3 from "../images/carousel/c3.png"
import image4 from "../images/carousel/c4.png"
import image5 from "../images/carousel/c5.png"
import image6 from "../images/carousel/c6.png"
import image7 from "../images/carousel/c7.png"
import image8 from "../images/carousel/c8.png"
import image9 from "../images/carousel/c9.webp"
import image10 from "../images/carousel/c10.gif"
import image11 from "../images/carousel/c11.png"
import image12 from "../images/carousel/c12.gif"
import image13 from "../images/carousel/c13.png"
import image14 from "../images/carousel/c14.gif"
import image15 from "../images/carousel/c15.png"

const imageData = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15
]

export default imageData;