import image1 from "./images/pokemon-ash.gif"
import image2 from "./images/legendary.png"
import image3 from "./images/pokesolprof.png"
import image4 from "./images/plant.gif"
import image5 from "./images/chad.png"
import image6 from "./images/pack.png"
import image7 from "./images/title.png"

const data = {
    images: [ image3, image3, image3, image3, image3, image1, image1, image3, image1, image2, image4, image3, image5, image3,
    image6, image3, image5, image3, image7 ],
    text: [
      'Hello there!',
      'Welcome to the world of $POKESOL!',
      'This world is full of memes and degenerate stuff',
      'My name is Professor Sol! People call me the',
      'SHITCOIN MASTER!',
      'time to jeet',
      'give me some $POKESOL based dev',
      'there are no airdrops ser',
      'going for my secret funds wait me',
      'its time to release the legendary!',
      '!!!',
      'really bro?',
      'professor,  I came for my Pokémon. Do you have it for me?',
      'Choose wisely, Chad.',
      'Pepemander, Pepesquirtle and bulbapepo!',
      'Which one do you choose?',
      'Yes',
      'Yes',
      'BUY THIS SHIT',
    ],
  };
  
export default data;
  