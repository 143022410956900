// Carousel.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./Carousel.css";

import arrowIcon from '../images/masterball.png'; // Ajusta la ruta según tu estructura de archivos


const CustomPrevArrow = (props) => (
    <div {...props} className="custom-arrow custom-prev-arrow">
        <img src={arrowIcon} alt="Left Arrow" />
    </div>
);

const CustomNextArrow = (props) => (
    <div {...props} className="custom-arrow custom-next-arrow">
        <img src={arrowIcon} alt="Left Arrow" />
    </div>
);

const Carousel = ({ images }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };



    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Carousel Image ${index}`} className="carousel-image" />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
